import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/job-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-lg font-bold text-white`;
  const HighlightedText = tw.span`bg-[#ee2833] text-white px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Compra, vende y encuentra <HighlightedText>lo que necesitas.</HighlightedText></>}
        description="Ofértalo es una plataforma donde puedes comprar y vender productos, ofrecer o contratar servicios, ofrecer o buscar Empleo. 
        ¡Todo en un solo lugar!."
        imageSrc="https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        watchVideoButtonText="Conoce la plataforma"
      />
      <MainFeature
        subheading={<Subheading>¿Qué es Ofertalo?</Subheading>}
        heading={
          <>
            Ofértalo es tu app para comprar, vender, ofrecer y contratar servicios, y encontrar o publicar empleos. <HighlightedText>¡Todo lo que necesitas en un solo lugar!</HighlightedText>
          </>
        }
        description={
          <Description>
            ¿Buscas algo específico o quieres llegar a más personas con tus productos y servicios? Con Ofértalo, tienes la libertad de conectarte directamente con otros usuarios, sin comisiones ni intermediarios. Explora todas las oportunidades que te esperan y haz que cada interacción cuente. 
            <br />
            <br />
            Ofértalo es más que una app: es tu espacio para hacer negocios y construir conexiones.
            <br />
            <br />
            Ofértalo nace con la misión de ser una plataforma integral donde podrás comprar y vender productos, contratar y ofrecer servicios, y encontrar empleo, todo en un solo lugar. En Ofértalo, nuestro objetivo es ofrecerte un espacio confiable para que interactúes libremente con otros miembros de la comunidad, sin comisiones ni intermediarios. Aquí, cada transacción e interacción es completamente directa entre los usuarios.
            <br />
            <br />
            En la actualidad, la mayoría de las personas busca realizar compras y ventas por internet, además de encontrar oportunidades de empleo.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={
          "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <Features
        heading={
          <>
            Te ofrecemos un <HighlightedText> servicio 3 en 1.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "App tipo Marketplace",
            description: "Donde podrás comprar y vender todo tipo de productos.",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "App de Servicios",
            description: "Aquí podrás encontrar y ofrecer servicios de todo tipo.",
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "App de Empleos",
            description: "¿Buscas empleo? Aquí podrás encontrarlo. ¿Ofreces empleo? Aquí podrás publicarlo.",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <DownloadApp
        text={<>Únete a nuestra plataforma y <HighlightedText>descarga la app</HighlightedText> para empezar a comprar y publicar.</>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
