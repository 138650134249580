import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import Swal from 'sweetalert2';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed font-black`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-[#ee2833]`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contacto",
  heading = <>Envienos<span tw="text-[#ee2833]"> un mensaje.</span><wbr/></>,
  description = "Si tiene alguna pregunta o desea contactarnos, complete el formulario a continuación y nos pondremos en contacto con usted lo antes posible.",
  submitButtonText = "Enviar Mensaje",
  textOnLeft = true,
}) => {
  
  const onSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Enviando mensaje...',
      didOpen: () => {
        Swal.showLoading()
      }
    });
    const formData = new FormData(e.target);

    formData.append("access_key", "4cd9d3fb-893c-4db9-a098-33b8e05d324b");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      Swal.fire({
        title: '¡Mensaje enviado!',
        text: 'Nos pondremos en contacto con usted lo antes posible.',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      e.target.reset();
    } else {
      console.log("Error", data);
      Swal.fire({
        title: 'Error!',
        text: 'Ocurrió un error al enviar el mensaje.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={(e) => onSubmit(e)}>
              <Input type="email" name="email" placeholder="Correo Electronico" />
              <Input type="text" name="name" placeholder="Nombre Completo" />
              <Input type="text" name="subject" placeholder="Asunto" />
              <Textarea name="message" placeholder="Mensaje" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
